class NavigationSideBar {

  run = () => {
    this.mySurveysTabClickListener();
    this.myResultsTabClickListener();
  };

  mySurveysTabClickListener = () => {
    $('.my-surveys').on('click', () => {
      // $('.recurring-surveys, .one-time-surveys').removeClass('hidden');
      // $('.recurring-results, .one-time-results').addClass('hidden');
    });
  };

  myResultsTabClickListener = () => {
    $('.my-results').on('click', () => {
      // $('.recurring-results, .one-time-results').removeClass('hidden');
      // $('.recurring-surveys, .one-time-surveys').addClass('hidden');
    });
  };

  hideDoubleAngles = () => {
    $('.collapse-navbar').addClass("hidden");
  }
}

export default NavigationSideBar;