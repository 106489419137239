import swal from 'sweetalert';
import React from "react";
require('es6-promise').polyfill();
require('isomorphic-fetch');
import XmxSwal from "./xmx_swal";
const _ = require('underscore');

class TeamMembersPage {
  constructor(opts) {
    this.teamMemberCount = opts.teamMemberCount;
    this.XmxMemberSwal = new XmxSwal();
  }

  run = () => {
    this.initGroupSelect();
    this.formSubmitListener();
    this.handleDelete();
    this.formEditListener();
    this.textFieldsListener();
    this.groupsListener();
  };



  handleDelete = () => {
    $('.icon-xred-archive').on('click', (e) => {
      e.preventDefault();
      let surveyId = e.target.dataset.id;
      let memberName = e.target.dataset.name;
      this.XmxMemberSwal.handleTeamMemberDelete(surveyId, memberName);
    });
  };

  hideTeamMemberList() {
    if (this.teamMemberCount == 0) {
      $('#tm-table_wrapper').hide();
      $('html').animate({ scrollTop: $('#tmmember-form').offset().top - 50 }, 'slow');
    }
    else{
      $('html').animate({ scrollTop: $('#tm-table_wrapper').offset().top - 75 }, 'slow');
    }
  }

  handleSwal = () => {
    $(".slack-link").on('click', (e) => {
      e.preventDefault();
      const memberId = e.currentTarget.dataset.memberId;
      const memberName = e.currentTarget.dataset.memberName;
      const memberEmail = e.currentTarget.dataset.memberEmail;
      const hasSlack = e.currentTarget.dataset.hasSlack === "true";

      swal({
        title: `Are you sure you want to ${ hasSlack ? 'revoke' : 'add'} Slack notifications?`,
        text: `${ memberName } ${ hasSlack ? 'won`t' : 'will' } receive notifications via Slack`,
        icon: "warning",
        buttons: {
          teamConfirm: `${ hasSlack ? 'Revoke' : 'Add' } for Team`,
          cancel: "Cancel",
          confirm: "Ok"
        }
      }).then(confirmed => {
        if (confirmed || confirmed === 'teamConfirm') {
          fetch(`api/team_member/handle_team_member_slack_notifications`, {
            credentials: 'include',
            method: 'post',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')},
            body: JSON.stringify({ id: memberId, email: memberEmail, confirmed: confirmed, hasSlack: hasSlack })
          })
          .then(() => {
            swal({
              title: "Done!",
              text: `Slack Notifications ${ hasSlack ? 'Revoked' : 'Added' }`,
              icon: 'success',
              timer: 3000,
              buttons: false
            }).then(() => { location.reload() });
          });
        }
      });
    });
  }

  initTeamMemberDataTable  = () => {
    $('#tm-table').dataTable({
      responsive: true,
      iDisplayLength: 15,
      lengthMenu: [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]],
      pagingType: 'simple',
      info: true,
      language: {
        'emptyTable': 'To add a new team member, fill out the form above and click SAVE',
        'zeroRecords': 'Oops! The person you\'re looking for isn\'t on this list...',
        'infoEmpty': 'Well, this is embarrassing! There\'s nothing here'
      }
    });
  };

  teamMemberRowCLickListener  = () => {
    const self = this;
    $('#tm-table').delegate('tr', 'click', function (e) {
      if ($(e.target).attr("class") != 'fas fa-edit blue') { return }

      $('html, body').animate({ scrollTop: $('.member-form').offset().top }, 'slow');

      let id = $(e.currentTarget).data('id');
      $.ajax({
        url: "team_members/" + id + "/edit",
        success: function (html) {
          $(".member-form").html(html);
          $("#auto-focus").focus();
          self.run();
        }
      });
    });
  };

  formEditListener = () => {
    $('.edit_team_member').on('submit', (e) => {
      e.preventDefault();
      e.stopPropagation();
      let form = e.target.elements
      let firstName = form['team_member_first_name'].value
      let lastName = form['team_member_last_name'].value
      let email = form['team_member_email'].value
      let sendWelcome = form['team_member_team_member_details'].checked
      let groups = $('#group_selector').val();
      $('#save-teammember').prop('disabled', true).val("SAVING...");

      fetch(`api/team_member/update`, {
        credentials: 'include', method: 'put',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')},
        body: JSON.stringify({
          first_name: firstName, last_name: lastName, email: email, team_member_details: sendWelcome, groups: groups
        })
      }).then(response => response.json())
        .then(responseData => {
          switch (responseData.data.status) {
            case 'updated':
              this.XmxMemberSwal.teamMemberModified(responseData.data);
              break;
            case 'validation_error':
              this.XmxMemberSwal.validationError();
              break;
            case 'no_surveys':
              this.XmxMemberSwal.teamMemberCreatedNoSurveys(responseData.data);
              break;
            default:
              this.XmxMemberSwal.standardError();
              break;
          }
        }).catch(error => {
        this.XmxMemberSwal.standardError();
      });
    });
  }

  groupsListener = () => {
    $(".select2-search__field").on("keydown", (e) => {
      this.submitBtnListener(e);
    });
  }

  submitBtnListener = (event) => {
    let formElements = event.currentTarget.form.elements
    let firstName = formElements['team_member_first_name'].value
    let lastName = formElements['team_member_last_name'].value
    let email = formElements['team_member_email'].value
    let allRequiredFieldsPresent = !(firstName == '' || lastName === '' || email === '');

    if (allRequiredFieldsPresent) {
      $('#save-teammember').removeClass('disabled');
    } else {
      $('#save-teammember').addClass('disabled');
    }
  }

  textFieldsListener = () => {
    $('#new_team_member, .edit_team_member').find('input[type="text"]').on('keyup', (e) => {
      this.submitBtnListener(e);
    });
  }

  formSubmitListener = () => {
    $('#tmmember-form').on('submit', (e) => {
      e.preventDefault();
      let form = e.target.elements
      let firstName = form['team_member_first_name'].value
      let lastName = form['team_member_last_name'].value
      let email = form['team_member_email'].value
      let sendWelcome = form['team_member_team_member_details'].checked
      let groups = $('#group_selector').val();
      $('#save-teammember').prop('disabled', true).val("SAVING...");

      fetch(`api/team_member/handle_team_member_creation`, {
        credentials: 'include', method: 'post',
        headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')},
        body: JSON.stringify({
          first_name: firstName, last_name: lastName, email: email, team_member_details: sendWelcome, groups: groups
        })
      }).then(response => response.json())
        .then(responseData => {
          switch (responseData.data.status) {
            case 'activated':
              this.XmxMemberSwal.teamMemberActivated(responseData.data);
              break;
            case 'created':
              this.XmxMemberSwal.teamMemberModified(responseData.data);
              break;
            case 'maximum':
              this.XmxMemberSwal.maximumTeamMembersReached();
              break;
            case 'validation_error':
              this.XmxMemberSwal.validationError();
              break;
            case 'no_surveys':
              this.XmxMemberSwal.teamMemberCreatedNoSurveys(responseData.data);
              break;
            default:
              this.XmxMemberSwal.standardError();
              break;
          }
        }).catch(error => {
          this.XmxMemberSwal.standardError();
        });
    });
  }

  initGroupSelect = () => {
    $('#group_selector').select2({
      placeholder: "Create a new group or select existing...",
      allowClear: true,
      tags: true,
      multiple: true,
      tokenSeparators: [',' , '\n', '\t'],
      class: 'groups_selector'
    });
  };
}

export default TeamMembersPage;