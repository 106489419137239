import swal from "sweetalert";
import React from 'react'
require('es6-promise').polyfill();
require('isomorphic-fetch');

class XmxSwal {
  // Team Member Page Sweet Alerts
  constructor(setInitialState) {
    this.setInitialState = setInitialState;
  };

  handleSlack = (memberId, memberName, memberEmail, hasSlack) => {
    swal({
      title: `Are you sure you want to ${ hasSlack ? 'revoke' : 'add'} Slack notifications?`,
      text: `${ memberName } ${ hasSlack ? 'won`t' : 'will' } receive notifications via Slack`,
      icon: "warning",
      buttons: {
        teamConfirm: `${ hasSlack ? 'Revoke' : 'Add' } for Team`,
        cancel: "Cancel",
        confirm: "Ok"
      }
    }).then(confirmed => {
      if (confirmed || confirmed === 'teamConfirm') {
        fetch(`api/team_member/handle_team_member_slack_notifications`, {
          credentials: 'include',
          method: 'post',
          headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')},
          body: JSON.stringify({ id: memberId, email: memberEmail, confirmed: confirmed, hasSlack: hasSlack })
        }).then(() => {
            swal({
              title: "Done!",
              text: `Slack Notifications ${ hasSlack ? 'Revoked' : 'Added' }`,
              icon: 'success',
              timer: 3000,
              buttons: false
            }).then(() => { this.setInitialState(); });
          });
      }
    });
  }

  handleTeamMemberDelete = (id, name) => {
    swal({
      title: 'Are You Sure?',
      text: `${name} will be deleted!`,
      icon: 'warning',
      buttons: true
    }).then(confirmed => {
      if (confirmed) {
        fetch(`/api/team_member/${id}`, {
          credentials: 'include', method: 'DELETE',
          headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')}
        }).then(response => response.json())
          .then(responseData => {
            switch (responseData.data.status) {
              case 'deleted':
                this.defaultSuccess(`Team Member was successfully deleted!`)
                break;
              default:
                this.standardError();
                break;
            }
          }).catch(error => {
          this.standardError();
        });
      } else { this.defaultActionSkip(); }
    })
  }

  selectAllSurveysListener = () => {
    $(document).on('click', "#select-all-surveys", function(e) {
      let checkboxes = document.querySelectorAll('input.checkbox-survey');
      for(let i=0, n=checkboxes.length;i<n;i++) { checkboxes[i].checked = e.target.checked; }
    });
  }

  teamMemberModified = (data) => {
    let teamMemberId = data.id;
    let elem = document.createElement("div");
    let updateSwalText = `You successfully updated ${data.name}!`
    let createSwalText = `You successfully added ${data.name} to your team!`
    elem.innerHTML = this.surveysTable(data);
    this.selectAllSurveysListener();

    swal({
      title: `🎉 Congratulations!`,
      text: data.status == 'created' ? createSwalText : updateSwalText,
      buttons: ["Skip", "Add"],
      className: "team-member-invite",
      closeOnClickOutside: false,
      icon: "success",
      content: elem
    }).then((confirmed) => {
      let surveysIds = []
      let checkboxes = document.querySelectorAll('input.checkbox-survey:checked:not(:disabled)')
      for (let i = 0; i < checkboxes.length; i++) { surveysIds.push(checkboxes[i].dataset.id) }

      if (confirmed) {
        fetch(`invite/${teamMemberId}`, {
          credentials: 'include', method: 'post',
          headers: {'Accept': 'application/json', 'Content-Type': 'application/json', 'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')},
          body: JSON.stringify({ surveys_ids: surveysIds })
        }).then(result => {
          if (result.status == 200) {
            this.defaultSuccess(`We added this team member to the selected surveys.`)
          } else { this.standardError(); }
        });
      } else { this.defaultActionSkip(); }
    });
  }

  teamMemberCreatedNoSurveys = (data) => {
    this.defaultSuccess(`${data.name} was successfully created!`);
  }

  teamMemberUpdated = (data) => {
    this.defaultSuccess(`${data.name} is updated!`);
  }

  teamMemberActivated = (data) => {
    this.defaultSuccess(`${data.name} is re-activated!`);
  }

  maximumTeamMembersReached = () => {
    this.defaultError('Team Members maximum is reached!');
  }

  // Default Sweet Alerts
  standardError = () => {
    this.defaultError('Uh oh! Something Went Wrong')
  }

  validationError = () => {
    this.defaultError('Team Member Has Incorrect Data');
  }

  defaultError = (text) => {
    swal({
      title: "Oops!",
      text: text,
      icon: 'error',
      timer: 3000,
      buttons: false
    }).then(() => { this.setInitialState(); });
  }

  defaultSuccess = (text) => {
    swal({
      title: "Done!",
      text: text,
      icon: 'success',
      buttons: false,
      timer: 3000
    }).then(() => { this.setInitialState(); });
  }

  defaultActionSkip = () => {
    swal({
      title: 'Skipped',
      text: 'No changes made!',
      icon: 'warning',
      timer: 3000,
      buttons: false
    }).then(() => { this.setInitialState(); })
  }

  //Utils
  surveysTable = (data) => {
    let tableBody = data.surveys.map(survey => {
      return `<tr>
                <td><input type="checkbox" data-id=${survey.id} ${survey.checked ? 'checked' : ''} ${survey.disabled ? 'disabled' : ''} class="checkbox-survey" name="survey-particiaption-checkbox"></td>
                <td class=${survey.series ? 'blue' : ''}>${survey.name}</td>
                <td>${survey.groups}</td>
              </tr>`
    })

    return `<table>
              <caption>Invite ${data.name} to an existing active and scheduled survey / series</caption>
              <thead>
                <tr>
                  <th><input id="select-all-surveys" type="checkbox" name='select-all-surveys-checkbox'/></th>
                  <th>Survey Series</th>
                  <th>Groups</th>
                </tr>
                </thead>
                <tbody>
                  ${tableBody}
                </tbody>
            </table>`
  }

  addToSurveysBtnListener = () => {
    $('.swal-button.swal-button--confirm').addClass('disabled');

    $('#new_team_member, .edit_team_member').find('input[type="text"]').on('keyup', (e) => {
      let formElements = e.currentTarget.form.elements
      let firstName = formElements['team_member_first_name'].value
      let lastName = formElements['team_member_last_name'].value
      let email = formElements['team_member_email'].value
      let allRequiredFieldsPresent = !(firstName == '' || lastName === '' || email === '');

      if (allRequiredFieldsPresent) {
        $('.swal-button.swal-button--confirm').removeClass('disabled');
      } else {
        $('.swal-button.swal-button--confirm').addClass('disabled');
      }
    });
  }
}

export default XmxSwal;
