class XmxNotice {
  static showNotice(msg, selector, alert) {
    selector = selector || ".xmx_notice, .team-member-notice";
    alert = alert || false;

    $(selector).each(function (index, div) {
      $(div).html(msg);
      $(div).show();
      if (alert) return $(div).addClass('validation-error');
    });

    setTimeout(function(){
      $(selector).each(function (index, div) {
        $(div).hide("slow");
      });
    }, 5000);
  };
}

export default XmxNotice;