class Flex_font {
    flexfont() {
        var divs = document.getElementsByClassName("flexFont");
        for (var i = 0; i < divs.length; i++) {
            var relFontsize = divs[i].offsetWidth * .005 - .075;
            divs[i].style.fontSize = relFontsize + 'em' ;
        }
    };
    run() {
        $(document).ready(() => {
            this.flexfont();
        });

        $(window).resize((e) => {
            this.flexfont();
        });
    }
}

export default Flex_font;