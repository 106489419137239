class Modal {
    preventEvent(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    triggerModal(e, self) {
        this.preventEvent(e);
        if ($(self).is(":checked")) {
            $("body").addClass("modal-open");
        } else {
            $("body").removeClass("modal-open");
        }
    }

    modalListener() {
     let self = this;
     $("#modal-1, #modal-2, #modal-3").on("change", function (e) {
        self.triggerModal(e, this);
      });
    }

    setMemberNamePreviewModal() {
      $('#team_member_first_name').on('change', (e) => {
        let memberName = e.target.value;
        $('.member-name').text('Hi ' + memberName);
      });
    }
    /* modal popup for help icon */
    modalPopupListener() {
        let self = this;
        $(".question-cir").click(function (e) {
            self.preventEvent(e);
            $(".helplayer").css({"opacity": "0.85", "z-index": "9"}).fadeIn("slow");
        });
    }

    modalCancelListener() {
        let self = this;
        $(".icon-cancel-circle").click(function (e) {
            self.preventEvent(e);
            $(".helplayer").hide();
        });
    }

    teamMembersFreeAccListener(exceededMembers) {
      if (exceededMembers) { $("#modal-2").click(); }
    };
}
export default Modal;