class RemindersPage {

  run = () => {
    this.clickEmailNotificationListener();
  }

  clickEmailNotificationListener = () => {
    const self = this;

    $('.email-notifications').on('click', function (e) {
      e.preventDefault();
      self.emailNotificationButtonState();
      $('#notification_form').submit();
    });
  }

  emailNotificationButtonState = () => {
    if ($('.email-notifications').hasClass('turn-off')) {
      $('#user_settings_report_ready_email, #user_settings_scheduled_xmetryx_email').prop("checked", false);
      $('.email-notifications').html("Get Them By Email <i class='fas fa-envelope-open-text' aria-hidden='true'>").removeClass('turn-off');
    } else {
      $('#user_settings_report_ready_email, #user_settings_scheduled_xmetryx_email').prop("checked", true);
      $(".email-notifications").text("Turn Off Email Notifications").addClass('turn-off');
      $(".email-notifications i").removeClass("fas fa-envelope-open-text");
    }
  }
}

export default RemindersPage;