import Utils from "./utils";

// used for mobile version of questions

class AppTooltip {

  constructor() {
    this.mobileVersion();
  }

  show() {
    if (Modernizr.touchscreen) {
      this._showTouchScreenTooltip();
    } else {
      this._showNonTouchScreenTooltip();
    }
  }

  mobileVersion() {
    Modernizr.addTest('touchscreen', function(){
      return Utils.isMobile();
    });
  }

  _showTouchScreenTooltip() {
    $('[title!=""]').qtip({
      style: {
        classes: 'qtip-bootstrap'
      },
      position: {
        my: 'top left',
        at: 'bottom right',
        viewport: $(window),
        adjust: {
          method: 'flip'
        }
      },
      show: {
        delay: 800
      },
      hide: {
        event: false,
        inactive: 3000
      }
    });
  }

  _showNonTouchScreenTooltip() {
    $('[title!=""]').qtip({
      style: {
        classes: 'qtip-bootstrap'
      },
      position: {
        my: 'top left',
        at: 'bottom right',
        viewport: $(window),
        adjust: {
          method: 'flip'
        }
      },
      show: {
        delay: 800
      }
    });
  }

  disableTooltip() {
    $('*').attr("title","");
    $('*').qtip('disable');
  }

}

export default AppTooltip;