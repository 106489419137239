class QuestionarySlider {

  constructor(opts) {
    this.questionnaryImage = opts['questionnary_image'];
    this.arrowImage = opts['arrow_image'];
  };

  preload () {
    const arrayOfImages = [];
    for (let iterator = -100; iterator <= 100; iterator += 10) {
      const questionnaryImage = this.questionnaryImage;
      const arrowImage = this.arrowImage;

      arrayOfImages.push(questionnaryImage.replace(/iterator/g, new String(iterator)));
      arrayOfImages.push(arrowImage.replace(/iterator/g, new String(iterator)));
    }
    $(arrayOfImages).each((i, el) => {
      $('<img/>')[0].src = el;
    });
  };

  render (opts) {
    const self = this;

    $(opts['slider_el']).slider({
      min: -100,
      max: 100,
      step: 1,
      value: 0,
      orientation: "vertical"
    }).slider("pips", {
      rest: "label",
      labels: []
    }).on("slide", (e, ui) => {
      const list = [];
      const iterator = (Math.round(ui.value / 10) * 10);
      if (ui.value > 0) {
        for (let i = 0; i <= iterator; i += 10) {
          list.push(`.ui-slider-pip-${i}`);
        }
      }
      else if (ui.value < 0) {
        for (let i = 0; i >= iterator; i -= 10) {
          list.push(`.ui-slider-pip-${i}`);
        }
      }
      else {
        list.push('.ui-slider-pip-0');
      }

      $(opts['slider_label']).css('opacity', '0.30');
      const select_label = $(list.toString());
      select_label.css('opacity', '1');

      const questionnaryImage = self.questionnaryImage;
      const arrowImage = self.arrowImage;

      $(opts['slider_images']).attr('src', questionnaryImage.replace(/iterator/g, new String(iterator)));
      $('.submit-arrow').attr('src', arrowImage.replace(/iterator/g, new String(iterator)));

    })
    .on('slidechange', (e, ui) => {
      $(opts['slider_axis']).val(ui.value);
      $('.submit-arrow').prop("disabled", false);
    });
  };
}


export default QuestionarySlider;
