class GoogleAuthy {

  flashMessage () {
    $('.admin-login-notice').slideDown(function() {
      setTimeout(function() {
        $('.admin-login-notice').slideUp();
      }, 5000);
    });
  }
}

export default GoogleAuthy;