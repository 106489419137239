class ReduceMembersModal {

   constructor(opts) {
     this.team_member_min_count = opts.team_member_min_count;
   }

   teamMembersAmountListener () {
    let memberCount = $('#team-members-table >tbody >tr').length;
    $('.tm-to-delete').text(memberCount - this.team_member_min_count)
    if (memberCount <= this.team_member_min_count) {
      $(".modal-close").removeClass('disabled-div');
      $(".exceeded-count").addClass('hidden');
    }
  }

  deleteTeamMemberListener() {
    $('.fa-trash-alt').on('click', (e) => {
      e.preventDefault();
      let memberId = e.target.dataset.id;
      let row = e.target.closest('tr')

      $.ajax({
        url: '/team_members/destroy_exceeded/' + memberId
      }).success(data => {
        row.remove();
        this.teamMembersAmountListener();
      });
    });
  }

}

export default ReduceMembersModal;