import React from 'react';
import ReactTooltip from "react-tooltip";

class Tooltip extends React.Component {
  render() {
    return (
        <ReactTooltip id={this.props.id} type={this.props.type}>
          <div className={this.props.className}>
            {this.props.tooltipContent}
          </div>
        </ReactTooltip>
    );
  }
}

export default Tooltip;
