class Parallax {
  parallax() {
    let plxBackground = $("#js-parallax-background");
    let plxWindow = $("#js-parallax-window");

    let plxWindowTopToPageTop = $(plxWindow).offset().top;
    let windowTopToPageTop = $(window).scrollTop();
    let plxWindowTopToWindowTop = plxWindowTopToPageTop - windowTopToPageTop;

    let plxSpeed = 0.35;

    plxBackground.css('top', -(plxWindowTopToWindowTop * plxSpeed) + 'px');
  }

  run() {
    $(document).ready(() => {
      this.parallax();
    });

    $(window).scroll((e) => {
      this.parallax();
    });
  }
}

export default Parallax;